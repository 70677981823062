import { useCallback, useState } from "react";
import { ApiResponse } from "./types";
import { getFetchItemNamesUrl } from "../utils/utils";

const useFetchItemNames = <T,>() => {
  const [response, setResponse] = useState<ApiResponse<T>>({
    data: undefined,
    error: undefined,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async (namePrefix: string) => {
    setLoading(true);
    try {
      const res = await fetch(getFetchItemNamesUrl(namePrefix));
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      //@ts-ignore
      const { items }: T = await res.json();
      setResponse({ data: items, error: undefined });
    } catch (error: any) {
      setResponse({ data: undefined, error: error.message });
    } finally {
      setLoading(false);
    }
  }, []);

  return { response, loading, fetchData };
};

export default useFetchItemNames