import { Box, Container } from "@mui/material";
import Footer from "../components/Footer";

export interface IPageProps {
  content: JSX.Element;
}

const Page = ({ content }: IPageProps) => {
return (
  <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  }}
>
  {/* Main content */}
  <Container component="main" sx={{ flexGrow: 1, py: 2 }}>
    {content}
  </Container>

  {/* Footer */}
  <Box
    component="footer"
    sx={{
      py: 2,
      px: 2,
      mt: 'auto',
      backgroundColor: (theme) =>
        theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    }}
  >
    <Container maxWidth="sm">
      <Footer />
    </Container>
  </Box>
</Box>
)
};

export default Page;
