import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress, Chip, ListItem, ListItemAvatar, Avatar, ListItemText } from "@mui/material";
import useFetchItemNames from "../hooks/useFetchItemNames";
import { getItemImageUrl } from "../utils/utils";

export interface Item {
  item_id: string;
  item_name: string;
}

interface AutocompleteWithAPIProps {
  onAutoCompleteChange: React.Dispatch<React.SetStateAction<Item[]>>;
  value: Item[];
  onRemoveItem: (item:Item)=>void;
}

const AutocompleteWithAPI: React.FC<AutocompleteWithAPIProps> = ({
  onAutoCompleteChange,
  value,
  onRemoveItem
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>("");
  const { response, loading, fetchData } = useFetchItemNames<Item[]>();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 500);

    return () => clearTimeout(handler);
  }, [inputValue]);

  useEffect(() => {
    if (debouncedInputValue) {
      fetchData(debouncedInputValue);
    }
  }, [debouncedInputValue, fetchData]);

  const handleOptionChange = (
    event: React.SyntheticEvent,
    newValue: Item[]
  ) => {
    onAutoCompleteChange(newValue); // Update the state directly
  };

  return (
    <Autocomplete
      multiple
      noOptionsText="No items found"
      options={response.data || []}
      getOptionLabel={(option: Item) => option.item_name}
      value={value}
      loading={loading}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      onChange={handleOptionChange}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemAvatar>
            <Avatar
              src={getItemImageUrl(option.item_id)}
              alt={option.item_name}
            />
          </ListItemAvatar>
          <ListItemText primary={option.item_name} />
        </ListItem>
      )}
      renderTags={(selected, getTagProps) =>
        selected.map((option, index) => (
          <Chip
            label={option.item_name}
            avatar={
              <img
                src={getItemImageUrl(option.item_id)}
                alt={option.item_name}
                width={16}
                height={16}
                style={{ borderRadius: "50%" }}
              />
            }
            {...getTagProps({ index })}
            onDelete={()=>onRemoveItem(option)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Items"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteWithAPI;
