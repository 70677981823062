export const getItemImageUrl = (itemId: string) => {
  return `https://d29aimug5u0wth.cloudfront.net/images/${itemId}.gif`
}

export const getFetchItemNamesUrl = (namePrefixStr: string) => {
  return `https://api.shn.gg/items?name_prefix=${namePrefixStr}`
}

export const getFetchItemUpdatesUrl = (itemId: string, startTime?: string) => {
  const itemIdStr = `item_id=${itemId}`;
  const startTimeStr = startTime ? `&start_time=${startTime}` : "";
  return `https://api.shn.gg/updates?${itemIdStr}${startTimeStr}`;
};