import { Grid2, Link, Stack, Typography } from "@mui/material";
import {
  DISCORD_INVITE_URL,
  DISCORD_LOGO_URL,
} from "../utils/static";

const Footer = () => {
  return (
    <Grid2 container alignItems="center" display="flex" justifyContent="center">
      <Stack>
        <Grid2 alignItems="center" display="flex" justifyContent="center">
          <img
            src={DISCORD_LOGO_URL}
            height={16}
            alt="logo"
            style={{ marginRight: "10px" }} // Add some spacing between the image and text
          />
          <Link href={DISCORD_INVITE_URL}>
            Join the discord for real-time alerts
          </Link>
        </Grid2>
        <Grid2 alignItems="center" display="flex" justifyContent="center">
          <Typography variant="subtitle1">Beta Release 2024-10-12</Typography>
        </Grid2>
      </Stack>
    </Grid2>
  );
};

export default Footer;
