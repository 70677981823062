import { useCallback, useState } from "react";
import { ApiResponse } from "./types";
import { getFetchItemUpdatesUrl } from "../utils/utils";

const useFetchItemUpdates = <T,>() => {
  const [response, setResponse] = useState<ApiResponse<T>>({
    data: undefined,
    error: undefined,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async (itemId: string, startTime?: string) => {
    setLoading(true);
    try {
      const res = await fetch(getFetchItemUpdatesUrl(itemId, startTime));
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const json = await res.json();
      //@ts-ignore
      const { data }: T = json;

      setResponse({ data, error: undefined });
      return data; // Return the data here
    } catch (error: any) {
      setResponse({ data: undefined, error: error.message });
      throw error; // Throw the error so it can be caught by the caller
    } finally {
      setLoading(false);
    }
  }, []);

  return { response, loading, fetchData };
};

export default useFetchItemUpdates;
